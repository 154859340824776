import React from "react"

import Layout from "../components/Layout/index"
import SEO from "../components/Seo"
import Container from "../components/Container"
import Button from "../components/Button"

import "../styles/pages/404.scss"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <Container className="not-found-wrapper">
      <h1>Page Not Found</h1>
      <Button isGatsbyLink to="/" size="large" variant="secondary">
        Back to Home
      </Button>
    </Container>
  </Layout>
)

export default NotFoundPage
